import arrow from '../assets/svg/dropdownarrow.svg';
import { breakpoints, colors } from '../styles';
import CircleIcon from './CircleIcon';
import React from 'react';
import styled from 'styled-components';

interface MoreInfoQuestion {
    title: string;
    placeholder: string;
    answers?: string[];
    keyString: string;
    key: string;
    currentAnswer: string;
    setCurrentAnswer: (arg0: string) => void;
    defaultValue?: string;
    returnIndex?: boolean;
    setShowJobHelp?: any;
}

const MoreInfoQuestionWrapper = styled.div``;

const Title = styled.span``;

const Dropdown = styled.select`
    appearance: none;
    border: 1px solid ${colors.white20};
    border-radius: 8px;
    background: white;
    color: ${colors.textColor1};
    width: 100%;
    height: 50px;
    padding: 15px;
    font-size: 13.5px;
    box-sizing: border-box;
    background-image: url('${arrow}');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    text-overflow: ellipsis;
    ${breakpoints.onlyMobile} {
        font-size: 13px;
    }
    ${breakpoints.sm} {
        width: 50%;
    }
    ${breakpoints.largeDesktop} {
        width: 85%;
    }
`;

const Input = styled.input.attrs(() => ({
    type: 'text',
}))`
    appearance: none;
    border: 1px solid ${colors.white20};
    border-radius: 8px;
    background: white;
    color: ${colors.textColor1};
    width: 100%;
    height: 50px;
    padding: 15px;
    font-size: 0.75em;
    box-sizing: border-box;
    ${breakpoints.onlyMobile} {
        font-size: 13px;
    }
`;

const ColoredOption = styled.option`
    ${breakpoints.onlyMobile} {
        font-size: 13px;
        width: 100%;
    }
    color: ${colors.black};
`;

const JobsCircleIcon = styled(CircleIcon)`
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 4px;
    margin-bottom: -4px;
    background-size: 18px;
`;

const MoreInfoFreetext: React.FC<MoreInfoQuestion> = ({
    title,
    placeholder,
    keyString,
    currentAnswer,
    setCurrentAnswer,
    defaultValue = '',
}): React.ReactElement => (
    <MoreInfoQuestionWrapper key={keyString}>
        <Title
            dangerouslySetInnerHTML={{
                __html: title,
            }}
        />
        <Input
            placeholder={placeholder}
            value={currentAnswer || defaultValue}
            onChange={(e) => setCurrentAnswer(e.currentTarget.value)}
        ></Input>
    </MoreInfoQuestionWrapper>
);

const MoreInfoDropdown: React.FC<MoreInfoQuestion> = ({
    title,
    placeholder,
    answers = [],
    keyString,
    currentAnswer,
    setCurrentAnswer,
    returnIndex = false,
    setShowJobHelp,
}): React.ReactElement => (
    <MoreInfoQuestionWrapper key={keyString}>
        <Title
            dangerouslySetInnerHTML={{
                __html: title,
            }}
        />

        {setShowJobHelp && (
            <JobsCircleIcon
                type="info"
                active={true}
                backgroundColor={colors.white}
                onClick={() => setShowJobHelp(true)}
            />
        )}
        <Dropdown
            onChange={(e) =>
                setCurrentAnswer(
                    returnIndex
                        ? e.currentTarget.selectedIndex.toString()
                        : e.currentTarget.value,
                )
            }
            defaultValue={(!currentAnswer && placeholder) || undefined}
            value={currentAnswer || undefined}
        >
            <ColoredOption disabled={true}>{placeholder}</ColoredOption>
            {answers.map((answer) => (
                <ColoredOption key={answer}>{answer}</ColoredOption>
            ))}
        </Dropdown>
    </MoreInfoQuestionWrapper>
);

export { MoreInfoFreetext, MoreInfoDropdown };
