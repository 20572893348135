import { colors, fonts } from '../styles';
import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {
    label: string;
    onClick: (arg0: boolean) => void;
}

interface CheckboxCSSProps {
    checked: boolean;
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    /* clippath: inset(50%); */
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const Icon = styled.div`
    background-color: ${colors.libertyBlue};
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
`;

const StyledCheckbox = styled.div<CheckboxCSSProps>`
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;

    background: transparent;
    border: 2px solid ${colors.libertyBlue};
    border-radius: 3px;
    transition: all 150ms;
    position: relative;
    cursor: pointer;
    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }
    ${Icon} {
        position: absolute;
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Label = styled.div`
    color: ${colors.libertyBlue};
    margin-left: 10px;
    ${fonts.content};
    line-height: 1.75;
    cursor: pointer;
`;

const Checkbox: React.FC<CheckboxProps> = ({ label, onClick }) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(!checked);
        onClick(checked);
    };

    return (
        <CheckBoxWrapper onClick={() => handleChange()}>
            <HiddenCheckbox readOnly checked={checked} />
            <StyledCheckbox checked={checked}>
                <Icon />
            </StyledCheckbox>
            <Label>{label}</Label>
        </CheckBoxWrapper>
    );
};

export default Checkbox;
